import { GridRowParams } from '@material-ui/data-grid';
import React, { useState, useCallback, SyntheticEvent, useEffect } from 'react';
import Separator from '~/components/Separator';

import { Radio } from '@atlaskit/radio';
import { toast } from 'react-toastify';
import api from '~/services/api';
import Search from '~/components/Search';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import ToggleDefault from '~/components/ToggleDefault';
import { InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations/schema';

// import useAuth from '~/hooks/useAuth';

const TipoAjusteEstoque: React.FC = () => {
  // const user = useAuth();
  const [showSearch, setShowSearch] = useState(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [codAjuste, setCodAjuste] = useState<number>(0);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setValue('tipo_operacao', '0');
    setValue('tipo_unidade', 0);
  }, []);

  const watchTipoUnidade = watch('tipo_unidade');
  const watchTipoOperacao = watch('tipo_operacao');
  const watchInativo = watch('flg_inativo');
  const watchInativoNf = watch('flg_ativo_nf');

  const onRowClick = (param: GridRowParams) => {
    const { row } = param;
    setIsUpdate(true);
    setCodAjuste(row.cod_tipo_ajuste);
    setShowSearch(false);

    setValue('des_ajuste', row.des_ajuste);
    setValue('tipo_unidade', String(row.tipo_unidade));
    setValue('tipo_operacao', String(row.tipo_operacao));
    setValue('flg_inativo', row.flg_inativo);
    setValue('flg_ativo_nf', row.flg_ativo_nf);
  };

  const handleClearForm = useCallback(() => {
    setCodAjuste(0);
    reset();

    setValue('tipo_operacao', '0');
    setValue('tipo_unidade', '0');
    setValue('des_ajuste', '');
    setValue('flg_inativo', false);
    setValue('flg_ativo_nf', false);
  }, []);

  const handleChange = useCallback(() => {
    setValue('flg_inativo', !watchInativo);
  }, []);

  const handleChangepp = useCallback(() => {
    setValue('flg_ativo_nf', !watchInativoNf);
  }, []);

  const onChangeTipoOperacao = useCallback(
    ({ currentTarget: { value } }: SyntheticEvent<any>) => {
      setValue('tipo_operacao', value);
    },
    [],
  );

  const onChangeTipoUnidade = useCallback(
    ({ currentTarget: { value } }: SyntheticEvent<any>) => {
      setValue('tipo_unidade', value);
    },
    [],
  );

  /**
   * Salva ou edita um tipo de ajuste
   */

  const onSave = handleSubmit(async (dataForm) => {
    if (
      dataForm.des_ajuste.trim() === 'NENHUM' ||
      dataForm.des_ajuste.trim() === 'NENHUMA' ||
      dataForm.des_ajuste.trim() === 'NENHUM(A)'
    ) {
      return toast.warn(
        'Descrição contém palavra não permitida "Nenhum/Nenhuma".',
      );
    }
    const tipoAjuste = {
      des_ajuste: dataForm.des_ajuste,
      tipo_operacao: dataForm.tipo_operacao,
      tipo_unidade: dataForm.tipo_unidade,
      flg_inativo: dataForm.flg_inativo,
      flg_ativo_nf: dataForm.flg_ativo_nf,
      cod_usuario_cadastro: 3,
      cod_usuario_alteracao: 3,
    };
    /**
     * Verifica se é create ou update e faz a devida chamada na API
     */
    if (isUpdate) {
      const { data } = await api.put(
        `/tipoajusteestoque/${codAjuste}`,
        tipoAjuste,
      );
      if (data.success) {
        toast.success(data.message);
        handleClearForm();
        setShowSearch(true);
      }
      return;
    }
    const { data } = await api.post('/tipoajusteestoque', tipoAjuste);
    if (data.success) {
      toast.success(data.message);
      handleClearForm();
    } else {
      if (!data.success) {
        toast.warning(data.message);
        return;
      }
      throw new Error(data.message);
    }
  });

  /**
   * Inativa um tipo de ajuste
   */
  const handleDelete = useCallback(async () => {
    if (codAjuste === 999 || codAjuste === 998) {
      return toast.warning(
        'Tipo de ajuste de estoque fixo, não permite ser inativado.',
      );
    }
    const { data } = await api.delete(`/tipoajusteestoque/${codAjuste}`);
    const { success, message } = data;
    if (success) {
      toast.success(message);
      setShowSearch(true);
      handleClearForm();
    }
  }, [codAjuste, handleClearForm]);

  const newData = () => {
    handleClearForm();
    setShowSearch(false);
    setIsUpdate(false);
  };

  return (
    <Container>
      {showSearch && (
        <Search codTela={22} newData={newData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={22}
          title="Tipo de Ajuste de Estoque"
          onSave={async () => {
            await onSave();
          }}
          codigoRegistro={[
            {
              value: codAjuste,
              des_campo: 'Código',
            },
          ]}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={newData}
          onDelete={handleDelete}
          labelButtonTrash="Inativar"
          deleteMessage="Deseja realmente inativar o registro?"
          onClearFields={handleClearForm}
          onReturnSearch={() => setShowSearch(true)}
        >
          <div className="row">
            {/* Descrição do Tipo do Ajuste de Estoque */}
            <div className="col-sm-12 col-md-12">
              <InputText
                label="Descrição"
                maxLength={50}
                caseInput="upper"
                placeholder="Informe a Descrição"
                name="des_ajuste"
                register={register}
                control={control}
                isError={!!errors.des_ajuste}
              />
            </div>
          </div>

          <div
            className="row"
            style={{
              marginTop: '10px',
            }}
          >
            {/* Tipo da Operação */}
            <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center">
              <p className="sc-iqHYGH bMXcXH me-2">Tipo de Operação:</p>
              <Radio
                value="0"
                label="(+) Entrada"
                name="tipo-operacao"
                testId="radio-default"
                isChecked={watchTipoOperacao === '0'}
                onChange={onChangeTipoOperacao}
              />

              <Radio
                value="1"
                label="(-) Saída"
                name="tipo-operacao"
                testId="radio-default"
                isChecked={watchTipoOperacao === '1'}
                onChange={onChangeTipoOperacao}
              />
            </div>

            <div
              className="col-sm-12 ml-4 col-md-12 col-lg-4 d-flex align-items-center"
              style={{
                borderLeft: '1px solid #ddd',
              }}
            >
              {/* Tipo da Unidade */}
              <p className="sc-iqHYGH bMXcXH me-2">Tipo de Unidade:</p>
              <Radio
                value="0"
                label="UN Venda"
                name="tipo-unidade"
                testId="radio-default"
                isChecked={watchTipoUnidade === '0'}
                onChange={onChangeTipoUnidade}
              />

              <Radio
                value="1"
                label="UN Compra"
                name="tipo-unidade"
                testId="radio-default"
                isChecked={watchTipoUnidade === '1'}
                onChange={onChangeTipoUnidade}
              />
            </div>
            <div
              className="col-sm-12 col-md-2"
              style={{
                borderLeft: '1px solid #ddd',
              }}
            >
              {/* Inativo */}
              <ToggleDefault
                onSwitch={handleChange}
                labelText="Inativo?"
                setChecked={watchInativo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Separator labelText="Características" />
            </div>
            <div className="col-12">
              <ToggleDefault
                onSwitch={handleChangepp}
                labelText="Ativo para NF?"
                setChecked={watchInativoNf}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default TipoAjusteEstoque;
